/************selectize***************/
.field-selectize{
    background: white;
    height: 38px;
    padding: 9px;
    padding-left: 14px;
    width: 100%;
    border: 1px solid rgba(34,36,38,.15);
}
.field-selectize-container{
    width: 100%;
}

.row-container-selectize{
    background: white;
    position: absolute;
    width: 423px;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    margin-top: 0px;
    z-index: 100;
}
.error.row-container-selectize.show {
    margin-top: -41px !important;  
}

.input-selectize > .ui.input:hover{
    border-color: rgba(34,36,38,.35);
    box-shadow: none;
}


.field-selectize:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
    color: white;
    font-weight: bold;
}

/************selectize***************/